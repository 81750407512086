@tailwind base;
@tailwind components;
@tailwind utilities;

.elementor-widget-container {
  -webkit-transition: -webkit-transform .25s cubic-bezier(.25,.88,.54,.98);
  transition: -webkit-transform .25s cubic-bezier(.25,.88,.54,.98);
  -o-transition: transform .25s cubic-bezier(.25, .88, .54, .98);
  transition: transform .25s cubic-bezier(.25,.88,.54,.98);
  transition: transform .25s cubic-bezier(.25,.88,.54,.98), -webkit-transform .25s cubic-bezier(.25,.88,.54,.98);
  will-change: transform;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}
.jet-parallax-section__layout {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
  pointer-events: none;
  -webkit-perspective: 1200px;
  perspective: 1200px;
}
 .img1{
  background-size: auto; 
  background-position: 30% 34%; 
  background-image: url(https://ld-wp73.template-help.com/wordpress/prod_25271/v1/wp-content/uploads/2019/08/2-1.png); 
  transform: translateY(104.2px);
}
.img2{
  
    background-size: auto;
    background-position: 36.3px 73px;
    background-image: url(https://ld-wp73.template-help.com/wordpress/prod_25271/v1/wp-content/uploads/2019/08/2.png);
    transform: translateY(26px);
    filter: none;
    opacity: 1;

}
.banner{

    transform: translateY(43px);
    
}
.elementor-11 .elementor-element.elementor-element-b52cc38 .elementor-repeater-item-5f9785e.jet-parallax-section__layout .jet-parallax-section__image {
  background-image: url(https://ld-wp73.template-help.com/wordpress/prod_25271/v1/wp-content/uploads/2019/08/2-1.png) !important;
}
.elementor-11 .elementor-element.elementor-element-b52cc38 .elementor-repeater-item-692a533.jet-parallax-section__layout .jet-parallax-section__image {
  background-image: url(https://ld-wp73.template-help.com/wordpress/prod_25271/v1/wp-content/uploads/2019/08/2.png) !important;
}
.swiper-button-disabled {
  opacity: 0.5;
  pointer-events: none;
}

.overlay {
  height: 100%;
  width: 0;
  position: fixed;
  /* z-index: 1; */
  top: 0;
  left: 0;
  background-color: rgb(5, 80, 28);
  background-color: #255852e6;
  overflow-x: hidden;
  transition: 0.5s;
}

.overlay-content {
  position: relative;
  top: 5%;
  width: 100%;
  
  margin-top: 30px;
}

.overlay a {
  padding: 8px;
  text-decoration: none;
  font-size: 36px;
  color: white;
  display: block;
  transition: 0.3s;
}

.overlay a:hover, .overlay a:focus {
  color: #f1f1f1;
}

.overlay .closebtn {
  position: absolute;
  top: 20px;
  right: 45px;
  font-size: 60px;
}

@media screen and (max-height: 450px) {
  .overlay a {font-size: 20px}
  .overlay .closebtn {
  font-size: 40px;
  top: 15px;
  right: 35px;
  }
}
.mySwiper2 .swiper-button-next,.mySwiper2 .swiper-button-prev{
  display: none;
}